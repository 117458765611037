<template>
  
</template>

<script>
import axios from 'axios'; // https://github.com/axios/axios
import { mixin as mixinHelperUtils, mixinAuth } from '@/common/helperUtils';

export default {
  name: 'GoogleLogin',
  mixins: [mixinHelperUtils, mixinAuth],
  data() {
    return {
      type: null,
      code: null
    }
  },
  computed: {
  },
  created() {
    this.type = this.$route.params.type
  },
  async mounted() {
    const url = this.$route.fullPath;
    const queryStringArray = url.substring(url.indexOf('?') + 1).split('&');
    var access_token = null;
    queryStringArray.forEach(function(v) {
      if (v.length > 13 && v.substring(0, 13) == 'access_token=') {
        access_token = v.substring(13);
      }
    });
    if (access_token) {
      this.getInfo(access_token)
    }
  },
  methods: {
    async getInfo(access_token) {
      const profile = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`)

      const userInfo = {
        email: profile.data.email,
        name: profile.data.name,
        snsName: this.$t('sns.google'),
        apply_path: '1102',
        access_token: access_token,
      };
      // /**
      //  * 인증 이벤트
      //  */
      if (this.type === 'signIn') {
        this.signIn(userInfo);
      } else if (this.type === 'signUp') {
        this.signUp(userInfo);
      }
    }
  },
};
</script>

<style scoped>
</style>

